import React from "react";
import { Chip, Typography } from "@material-ui/core";
import { getTipoPublicacion } from "../PublicacionesMercadoLibre/utils";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";

export const getErrorStock = (data, publicacion, useArtVinculacion) => {
  if (publicacion && publicacion.tipo === "free") {
    //Validamos por tipo de publicacion
    return true;
  } else {
    for (let i = 0; i < useArtVinculacion.articulosSelected.length; i++) {
      const articulo = useArtVinculacion.articulosSelected[i];
      if (articulo.repuesto_sucursal === null) {
        return true;
      }
      if (
        (articulo && !articulo.repuesto_sucursal) ||
        (articulo &&
          articulo.repuesto_sucursal &&
          articulo.repuesto_sucursal.stockDisponible <= 0)
      ) {
        return true;
      }
    }
    return false;
  }
};

export const columnasCardTablaPublicacionesMeli = [
  "Imagen",
  "Id",
  "Vinculada",
  "Tipo",
  "Precio",
];

export const handleFormatPublicacionesCardMeli = ({
  publicaciones,
  datosVinculacion,
}) => {
  return publicaciones.map((publicacion) => {
    const precioTotal = Number(datosVinculacion.precioTotal);
    const precioPublicacion = Number(publicacion.precio);

    const precioVariant =
      precioTotal > 0 && precioPublicacion !== precioTotal ? "h6" : "h5";
    const precioColor =
      precioTotal > 0 && precioPublicacion !== precioTotal
        ? "error"
        : "primary";
    const precioTextDecoration =
      precioTotal > 0 && precioPublicacion !== precioTotal
        ? "line-through"
        : "";

    return {
      imagen: (
        <img
          alt="Imagen"
          src={publicacion ? publicacion.imagen : ""}
          style={{
            width: "4rem",
            objectFit: "contain",
          }}
        />
      ),
      id: publicacion ? publicacion.id : "",
      vinculada: publicacion.vinculada
        ? "Vinculada (se actualizara)"
        : "Sin vincular",
      tipo: (
        <Chip
          label={getTipoPublicacion(publicacion.tipo).name.toUpperCase()}
          size="small"
          style={{
            color: "white",
            backgroundColor: getTipoPublicacion(publicacion.tipo).color,
          }}
        />
      ),
      precio: (
        <>
          <Typography
            color={precioColor}
            variant={precioVariant}
            style={{ textDecoration: precioTextDecoration }}
          >
            {`${publicacion.precio.toLocaleString("es-AR")}`}
          </Typography>
          {precioTotal > 0 && precioPublicacion !== precioTotal && (
            <>
              <ArrowDownwardIcon color="primary" />
              <Typography variant="h5" color="primary">
                {`$${parseFloat(precioTotal).toLocaleString("es-AR")}`}
              </Typography>
            </>
          )}
        </>
      ),
    };
  });
};

export const getDataVariants = (publicacion) => {
  let articuloBase = publicacion.dataApi
    ? publicacion.dataApi.products[0]
    : null;

  let newData = [];
  if (publicacion.meliData && publicacion.meliData.variations.length > 0) {
    publicacion.meliData.variations.forEach((variante) => {
      let newArticle = getArticleVariante(publicacion, variante.id);

      let newVariante = {
        ...variante,
        new_price: publicacion.price || 0,
        article: newArticle,
        is_base_price:
          newArticle &&
          newArticle.idRepuestoProveedor === articuloBase.idRepuestoProveedor
            ? true
            : false,
        stock:
          newArticle && newArticle.repuesto_sucursal
            ? newArticle.repuesto_sucursal.stockDisponible
            : 0,
        sync_stock: newArticle ? newArticle.dataVariante.sync_stock : false,
      };

      newData.push(newVariante);
    });
  }
  return newData;
};

const getArticleVariante = (publicacion, idVariante) => {
  let publicacionVarianteBoxer = publicacion.dataApi
    ? publicacion.dataApi.variants.filter((v) => v.variant_id === idVariante)[0]
    : null;

  return publicacionVarianteBoxer
    ? {
        ...publicacionVarianteBoxer.products[0],
        cantidad: 1,
        subtotal: publicacionVarianteBoxer.products[0].precios.venta,
        dataVariante: publicacionVarianteBoxer,
      }
    : null;
};

/**
 * Funcion que compara las variantes de la publicacion con las variantes de la data
 * @param {Array} publicacion Publicacion de la que se obtienen las variantes
 * @param {Array} variantesData Variantes seteadas en data y a las que se le modifica precio y stock
 * @returns {Boolean} Retorna true si las variantes son iguales, false en caso contrario
 */
export const compareDataVariants = (publicacion, variantesData) => {
  const variantesPublicacion =
    publicacion &&
    publicacion.meliData &&
    publicacion.meliData.variations.length > 0
      ? publicacion.meliData.variations
      : [];

  const idsVariantesPublicacion = variantesPublicacion
    .map((v) => v.id)
    .sort((a, b) => a - b);

  const idsVariantesData = variantesData.map((v) => v.id).sort((a, b) => a - b);

  return idsVariantesPublicacion.length === 0
    ? false
    : idsVariantesPublicacion.every(
        (id, index) => id === idsVariantesData[index],
      );
};

/**
 * Funcion que busca las variantes de la api de boxer que no se hayan enviado en la data de variantes de meli. Es decir, aquellas que ya se deben
 * deshabilitar en boxer
 * @param {Object} publicacion Publicacion de la que se obtienen las variantes
 */
export const getVariantsBoxerDisabled = (publicacion) => {
  const variantesPublicacion =
    publicacion &&
    publicacion.meliData &&
    publicacion.meliData.variations.length > 0
      ? publicacion.meliData.variations
      : [];
  const variantesBoxer =
    publicacion && publicacion.dataApi && publicacion.dataApi.variants;
  const idsVariantesPublicacion = variantesPublicacion.map((v) => v.id);

  //Filtra las variantes de boxer que esten activas y que no esten en la publicacion de meli
  const variantesDisabled = variantesBoxer.filter(
    (v) =>
      v.is_active === true && !idsVariantesPublicacion.includes(v.variant_id),
  );

  return variantesDisabled.map((v) => v.variant_id);
};

/**
 * Funcion que verifica si todas las variantes tienen un producto asignado y si esta definida con precio base
 * @param {Object} data Data de la publicacion
 * @returns {Boolean} Retorna true si todas las variantes tienen un producto asignado y solo una tiene precio base, false en caso contrario
 */
export const verifyVariantsWithArticle = (data, publicacion) => {
  //Primero se verifica que todas las variantes tengan los atributos necesarios
  const allVariantsHaveAttributes = data.variantes.every(
    (variant) =>
      variant.hasOwnProperty("is_base_price") &&
      variant.hasOwnProperty("sync_stock") &&
      variant.hasOwnProperty("stock") &&
      variant.hasOwnProperty("article"),
  );
  if (allVariantsHaveAttributes) {
    // Verificar que todos los artículos de las variantes no sean null
    const todosTienenArticulo = data.variantes.every(
      (vari) => vari.article !== null,
    );

    // Filtrar las variantes que tienen precioBase true
    const variantesConPrecioBase = data.variantes.filter(
      (vari) => vari.is_base_price,
    );

    // Verificar que solo una variante tenga precioBase true. Si es edicion de vinculacion, puede haber mas de una ya que puede haber variantes que usen el
    // mismo articulo base. En ese caso, se permite que haya mas de una variante con precioBase true solo si el idRepuestoProveedor es el mismo
    if (publicacion.vinculada) {
      const idsRepuestosProveedor = variantesConPrecioBase.map(
        (vari) => vari.article.idRepuestoProveedor,
      );
      const unicoIdRepuestoProveedor =
        new Set(idsRepuestosProveedor).size === 1;

      return todosTienenArticulo && unicoIdRepuestoProveedor;
    } else {
      const unaTienePrecioBase = variantesConPrecioBase.length === 1;
      return todosTienenArticulo && unaTienePrecioBase;
    }
  } else {
    return false;
  }
};
