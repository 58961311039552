import React, { useState, useEffect } from "react";
import FormularioAgregarRepuesto from "./FormularioAgregarRepuesto";
import { validarFormRepuesto, postAgregarRepuesto } from "../FuncionesCatalogo";
import {
  successNotification,
  errorNotification,
} from "../../../../components/Notifications";
import { Box, Dialog, DialogTitle } from "@material-ui/core";

const ModalAgregarRepuesto = (props) => {
  const [artSelect, setArtSelect] = useState(null);
  const [definidos, setDefinidos] = useState(false);
  const [loading, setLoading] = useState(false);
  const [ubiSinDefinir, setUbiSinDefinir] = useState(false);
  const [form, setForm] = useState({
    precioVenta: "",
    precioLista: "",
    stockDisponible: 0,
    manejaStock: true,
    idDeposito: "",
    idZona: "",
    idEstante: "",
    idUbicacion: "",
    stock_deseado: 0,
    maneja_unidades: false,
    cantidad_unidades: 1,
  });
  const [invalidForm, setInvalidForm] = useState({
    precioVenta: false,
    precioLista: false,
    stockDisponible: false,
    manejaStock: false,
    idDeposito: false,
    idZona: false,
    idEstante: false,
    idUbicacion: false,
    cantidad_unidades: false,
  });

  useEffect(() => {
    const { precios } = props.articuloSelected;
    setArtSelect({
      ...props.articuloSelected,
      precioVenta: precios.venta,
      precioLista: precios.lista,
    });
  }, []);

  useEffect(() => {
    if (artSelect) {
      getPreciosArticulo();
    }
  }, [artSelect]);

  useEffect(() => {
    if (form.manejaStock) {
      setForm({ ...form, stockDisponible: 0 });
    } else {
      setForm({ ...form, stockDisponible: 0 });
      setInvalidForm({ ...invalidForm, stockDisponible: false });
    }
  }, [form.manejaStock]);

  const completarDatos = () => {
    const deposito = localStorage.getItem("deposito");
    const estante = localStorage.getItem("estante");
    const zona = localStorage.getItem("zona");
    const fila = localStorage.getItem("fila");
    let datos = { idDeposito: "", idZona: "", idEstante: "", idUbicacion: "" };
    if (
      deposito !== null &&
      estante !== null &&
      zona !== null &&
      fila !== null
    ) {
      datos = {
        idDeposito: deposito,
        idZona: zona,
        idEstante: estante,
        idUbicacion: fila,
      };
    }
    return datos;
  };

  const getPreciosArticulo = async () => {
    let datos = completarDatos();
    setForm({
      ...form,
      precioVenta: artSelect.precioVenta,
      precioLista: artSelect.precioLista,
      idDeposito: datos.idDeposito,
      idZona: datos.idZona,
      idEstante: datos.idEstante,
      idUbicacion: datos.idUbicacion,
    });
    setInvalidForm({
      ...invalidForm,
      idDeposito: false,
      idZona: false,
      idEstante: false,
      idUbicacion: false,
    });
    setDefinidos(true);
    setLoading(false);
  };

  const onChangeInputs = (e) => {
    let updatedForm = {
      ...form,
      [e.target.name]: Object.keys(e.target).includes("checked")
        ? Boolean(e.target.checked)
        : e.target.value,
    };
    console.log(updatedForm);
    if (e.target.name === "maneja_unidades" && !e.target.checked) {
      updatedForm = { ...updatedForm, cantidad_unidades: 1 };
      setInvalidForm({
        ...invalidForm,
        [e.target.name]: false,
        cantidad_unidades: false,
      });
    }

    setForm(updatedForm);

    if (e.target.name !== "maneja_unidades") {
      setInvalidForm({ ...invalidForm, [e.target.name]: false });
    }
  };

  const handleChange = () => {
    setUbiSinDefinir(!ubiSinDefinir);
  };

  const handleChangeStock = () => {
    setForm({ ...form, manejaStock: !form.manejaStock });
  };

  const getZonas = async (value) => {
    setForm({
      ...form,
      idDeposito: value,
      idEstante: "",
      idUbicacion: "",
      idZona: "",
    });
    setInvalidForm({ ...invalidForm, idDeposito: false });
  };

  const getEstantes = async (value) => {
    setForm({ ...form, idZona: value, idUbicacion: "", idEstante: "" });
    setInvalidForm({ ...invalidForm, idZona: false });
  };

  const getFilas = async (value) => {
    setForm({ ...form, idUbicacion: "", idEstante: value });
    setInvalidForm({ ...invalidForm, idEstante: false });
  };

  const validacionForm = async (e) => {
    setLoading(true);
    e.preventDefault();
    let res = await validarFormRepuesto(form, invalidForm, ubiSinDefinir);
    if (res.valid) {
      postExisteRepuesto();
    } else {
      setInvalidForm({
        ...invalidForm,
        precioVenta: res.invalid.precioVenta,
        precioLista: res.invalid.precioLista,
        stockDisponible: res.invalid.stockDisponible,
        manejaStock: res.invalid.manejaStock,
        idDeposito: res.invalid.idDeposito,
        idZona: res.invalid.idZona,
        idEstante: res.invalid.idEstante,
        idUbicacion: res.invalid.idUbicacion,
      });
      setLoading(false);
    }
  };

  const postExisteRepuesto = async () => {
    await props.validarToken();
    if (!props.estadoValidacion) {
      let res = await postAgregarRepuesto(ubiSinDefinir, form, artSelect);
      if (res.status === 201) {
        props.updateArticulo(res.data);
        successNotification("El repuesto se agregó correctamente");
        setForm({
          precioVenta: "",
          precioLista: "",
          stockDisponible: "0",
          manejaStock: false,
          idDeposito: "",
          idZona: "",
          idEstante: "",
          idUbicacion: "",
        });
        setArtSelect(null);
        props.cerrarModal();
      } else {
        setLoading(false);
        errorNotification("Error al cargar el repuesto, por favor reintente");
      }
    } else {
      props.cerrarModal();
    }
  };

  return (
    <Dialog open={props.open} onClose={() => props.cerrarModal()}>
      <DialogTitle style={{ backgroundColor: "#8e9eab" }}>
        <Box display="flex" justifyContent={"center"}>
          AGREGAR A SUCURSAL EL ARTICULO:
        </Box>
        <Box display="flex" justifyContent={"center"}>
          {artSelect && artSelect.codOriginal}
        </Box>
      </DialogTitle>
      <FormularioAgregarRepuesto
        validacionForm={(e) => validacionForm(e)}
        onChangeInputs={(e) => onChangeInputs(e)}
        form={form}
        definidos={definidos}
        invalidForm={invalidForm}
        ubiSinDefinir={ubiSinDefinir}
        handleChangeStock={handleChangeStock}
        handleChange={handleChange}
        estadoValidacion={props.estadoValidacion}
        validarToken={props.validarToken}
        cerrarModal={() => {
          setArtSelect(null);
          props.cerrarModal();
        }}
        getZonas={(value) => getZonas(value)}
        getEstantes={(value) => getEstantes(value)}
        getFilas={(value) => getFilas(value)}
        loading={loading}
      />
    </Dialog>
  );
};

export default ModalAgregarRepuesto;
